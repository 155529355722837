import { UIState, Username } from '.';


type UIActionType =
   | {
      type: '[LOGIN] - Update Username',
      payload: Username
   }


export const loginReducer = (state: UIState, action: UIActionType): UIState => {

   switch (action.type) {

      case '[LOGIN] - Update Username':
         return {
            ...state,
            username: action.payload,
         }

      default:
         return state;
   }

}