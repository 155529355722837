"use client";
import { useEffect, useState } from "react";
import {
  ChevronRightIcon,
  CheckIcon,
  XMarkIcon
} from "@heroicons/react/24/solid";
import { useTranslations } from "next-intl";
interface Props {
  newPassword: string;
  touched: boolean;
}

enum status {
  initial,
  valid,
  invalid
}

export const PasswordRestrictions = ({ newPassword, touched }: Props) => {
  const t = useTranslations("auth.form.set-password.restrictions");
  useEffect(() => {
    if (touched) testPassword();
  }, [newPassword]);

  const [passwordStrength, setPasswordStrength] = useState({
    lowercase: status.initial,
    uppercase: status.initial,
    number: status.initial,
    length: status.initial
  });

  const testPassword = () => {
    setPasswordStrength({
      lowercase: /[a-z]/.test(newPassword) ? status.valid : status.invalid,
      uppercase: /[A-Z]/.test(newPassword) ? status.valid : status.invalid,
      number: /\d/.test(newPassword) ? status.valid : status.invalid,
      length: newPassword.length >= 8 ? status.valid : status.invalid
    });
  };

  const getColor = (prop) => {
    const map = {
      [status.initial]: "text-slate-400",
      [status.valid]: "text-green-600",
      [status.invalid]: "text-red-600"
    };
    return map[prop];
  };

  const getIcon = (prop) => {
    const map = {
      [status.initial]: <ChevronRightIcon className={"mr-1 h-3 w-3"} />,
      [status.valid]: <CheckIcon className={"mr-1 h-3 w-3"} />,
      [status.invalid]: <XMarkIcon className={"mr-1 h-3 w-3"} />
    };
    return map[prop];
  };

  return (
    <ul className={"text-x10 text-slate-400"}>
      <li
        className={`flex items-center ${getColor(passwordStrength.lowercase)}`}
      >
        {getIcon(passwordStrength.lowercase)}
        {t("lowercase")}
      </li>
      <li
        className={`my-2 flex items-center ${getColor(passwordStrength.uppercase)}`}
      >
        {getIcon(passwordStrength.uppercase)}
        {t("uppercase")}
      </li>
      <li
        className={`my-2 flex items-center ${getColor(passwordStrength.number)}`}
      >
        {getIcon(passwordStrength.number)}
        {t("number")}
      </li>
      <li className={`flex items-center ${getColor(passwordStrength.length)}`}>
        {getIcon(passwordStrength.length)}
        {t("length")}
      </li>
    </ul>
  );
};
