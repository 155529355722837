"use client";
import { useEffect, useState } from "react";
import { AuthNextStep, Channel, Platform } from "@nilo/codegen";
import {
  useSignin,
  useSetPassword,
  useValidationCode,
  useForgotPassword,
  resultUsername,
  usePlatformConfig
} from "@nilo/auth/hooks";
import {
  ValidationCodeForm,
  SetPasswordForm,
  SignInForm
} from "@nilo/auth/components/forms";
import UsernameContainer from "../username";
import { NiloAlert } from "@nilo/design";
import { MessageType } from "@nilo/design/src/components/alertMessage";
import { useTranslations } from "next-intl";
import { useAnalytics } from "@analytics";
import { UserNotVerified } from "../../static/user-not-verified";
import { getValidations } from "@nilo/auth/utils";

export interface PropsLogin {
  loginSuccessCallback: () => void;
  platform: Platform;
  usernameByHash?: string;
  codeByHash?: string;
  locale?: string;
  chatbotNumber?: string;
  isConfirmationLink?: boolean;
  isForgotLink?: boolean;
}

interface LoginState {
  username?: string;
  isLoading?: boolean;
  nextStep?: AuthNextStep;
  showUsername?: boolean;
  showValidationCode?: boolean;
  showCheckCredentials?: boolean;
  showSetPassword?: boolean;
  channel: Channel;
  signUpMessage?: string;
  showForgotPassword?: boolean;
  showUserNotVerified?: boolean;
}

export const Login = (props: PropsLogin) => {
  const t = useTranslations("auth.login");
  const { config } = usePlatformConfig();
  const { singIn } = useSignin();
  const { setPassword } = useSetPassword();
  const { validationCode } = useValidationCode();
  const { forgotPassword, confirmForgotPassword } = useForgotPassword();
  const { track } = useAnalytics("login");

  const [state, setState] = useState<LoginState>({
    showUsername: !props.isConfirmationLink && !props.isForgotLink,
    channel: config.defaultLoginMethod() as unknown as Channel,
    username: props.usernameByHash,
    showForgotPassword: props.isForgotLink
  });

  useEffect(() => {
    if (props.isConfirmationLink) confirmHandler();
  }, []);

  useEffect(() => {
    (state.showValidationCode || state.showUserNotVerified) && sendCode();
  }, [state.showValidationCode, state.showUserNotVerified]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isLoading:
        singIn.isLoading || setPassword.isLoading || validationCode.isLoading
    }));
  }, [singIn.isLoading, setPassword.isLoading, validationCode.isLoading]);

  useEffect(() => {
    if (setPassword.isAuthenticated || singIn.isAuthenticated) {
      props.loginSuccessCallback();
    }
  }, [setPassword.isAuthenticated, singIn.isAuthenticated]);

  useEffect(() => {
    singIn.requireSetPassword &&
      setState({
        ...state,
        showValidationCode: false,
        showCheckCredentials: false,
        showSetPassword: true
      });
  }, [singIn.requireSetPassword]);

  useEffect(() => {
    if (!confirmForgotPassword.automaticSignin) return;
    setState({
      ...state,
      showValidationCode: false,
      showCheckCredentials: false,
      showSetPassword: false
    });
    singIn.dispachtSingIn({
      username: state.username,
      password: confirmForgotPassword.newPassword
    });
  }, [confirmForgotPassword.automaticSignin]);

  const confirmHandler = () => {
    if (!props.usernameByHash || !props.codeByHash) return;
    dispachtSingIn(props.codeByHash);
  };

  const dispachtSingIn = (password: string) => {
    singIn.dispachtSingIn({ username: state.username, password });
  };

  const userCheckedHandler = ({ nextStep, username }: resultUsername) => {
    const validate = getValidations({
      config: config.get(),
      channel: state.channel,
      step: nextStep
    });

    setState({
      ...state,
      username,
      nextStep,
      showUsername: validate.showUsername,
      showValidationCode: validate.showValidationCode,
      showCheckCredentials: validate.showCheckCredentials,
      showUserNotVerified: validate.showUserNotVerified
    });
  };

  const channelHandler = (channel: Channel) => {
    setState((prev) => ({ ...prev, channel }));
  };

  const sendCode = () => {
    !validationCode.isLoading &&
      validationCode.distpatchSendCode(state.username);
  };

  const RenderUsernameContainer = () => {
    return (
      <UsernameContainer
        userCheckedCallback={userCheckedHandler}
        onChannelSelected={channelHandler}
        defaultChannel={state.channel}
        chatbotNumber={props?.chatbotNumber}
      />
    );
  };

  const RenderValidationCode = () => {
    return (
      <ValidationCodeForm
        deliveryMethod={validationCode.deliveryMethod}
        onSubmitCallback={dispachtSingIn}
        onResendCode={sendCode}
        isLoading={state.isLoading}
      />
    );
  };

  const RenderCheckCredentials = () => {
    const distpatchForgotPassword = () => {
      forgotPassword.dispatch(state.username);
      track.requestResetPassword();
    };

    return (
      <SignInForm
        onSubmitCallback={dispachtSingIn}
        isLoading={state.isLoading}
        onForgotPassword={distpatchForgotPassword}
      />
    );
  };

  const RenderSetPassword = () => {
    const setPasswordHandler = (newpassword: string) => {
      setPassword.dispachtSetPassword({
        newpassword,
        username: state.username,
        session: singIn.session
      });
    };
    return <SetPasswordForm onSubmitCallback={setPasswordHandler} />;
  };

  const RenderForgotPassword = () => {
    const setPasswordHandler = (newPassword: string) => {
      confirmForgotPassword.dispatch({
        username: state.username,
        confirmationCode: props.codeByHash,
        newPassword
      });
    };
    return <SetPasswordForm onSubmitCallback={setPasswordHandler} />;
  };

  const RenderForgotMessage = () => {
    return (
      <div className={`fixed bottom-20 left-0 right-0 px-3`}>
        <NiloAlert
          type={MessageType.WARNNING}
          message={t("forgotPasswordWarning.message", {
            channel:
              state.channel === Channel.Email
                ? t("forgotPasswordWarning.channel.email")
                : t("forgotPasswordWarning.channel.phone")
          })}
        />
      </div>
    );
  };

  return (
    <>
      {state.showUserNotVerified && <UserNotVerified />}
      {state.showUsername && <RenderUsernameContainer />}
      {state.showValidationCode && <RenderValidationCode />}
      {state.showCheckCredentials && <RenderCheckCredentials />}
      {state.showSetPassword && <RenderSetPassword />}
      {state.showForgotPassword && <RenderForgotPassword />}
      {forgotPassword.isForgotMessage && <RenderForgotMessage />}
    </>
  );
};
