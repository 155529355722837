"use client";

import * as Yup from "yup";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { NiloButton, NiloLink, NiloSpinner, typeButton } from "@nilo/design";
import { SelectChannel } from "./select-channel";
import { useContext, useState } from "react";
import { ChannelPhone } from "./channel-phone";
import { ChannelEmail } from "./channel-email";
import { Channel } from "@nilo/codegen";
import { countryCodeDefault } from "./channel-phone/country-phone-code";
import { LoginContext } from "@nilo/auth/context/login";
import { useTranslations } from "next-intl";
import { ChannelChatbot } from "./channel-chatbot";
import { useAnalytics } from "@analytics";
import { usePlatformConfig } from "@nilo/auth/hooks/use-platform-config";
import { LoginMethods } from "@nilo/auth/utils/platform-config";

interface Props {
  onSubmitCallback: (username: any) => void;
  onChannelSelected?: (channel: Channel) => void;
  isLoading?: boolean;
  defaultChannel: Channel;
  chatbotNumber?: string;
}

const formInitialValues = {
  email: "",
  phone: "",
  countryCode: countryCodeDefault
};

const byPhone = Channel.Phone,
  byEmail = Channel.Email;

export const UsernameForm = ({
  onSubmitCallback,
  onChannelSelected,
  defaultChannel,
  chatbotNumber
}: Props) => {
  const { config } = usePlatformConfig();
  const [channel, setChannel] = useState(defaultChannel);
  const [loginMethod, setLoginMethod] = useState(
    config.channeltoLoginMethod(defaultChannel)
  );
  const { updateUsername } = useContext(LoginContext);
  const t = useTranslations("auth.form.username");
  const { track } = useAnalytics("login");

  const onSubmitHandler = (
    { phone, email, countryCode },
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const username = channel === byPhone ? `${countryCode}${phone}` : email;
    updateUsername({ phoneNumber: phone, countryCode, email });
    onSubmitCallback(username);
    setSubmitting(false);
    track.submitUsername(channel);
  };

  const switchLoginMethod = (value: string, formik: FormikProps<any>) => {
    formik.setFieldValue(byEmail, "", false);
    formik.setFieldValue(byPhone, "", false);
    setChannel(value as Channel);
    setLoginMethod(value as LoginMethods);
    onChannelSelected(value as Channel); //TODO cambiar para que use LoginMethods
  };

  const validationSchema = Yup.object().shape({
    email:
      channel === byEmail
        ? Yup.string()
            .email(t("yupValidation.email"))
            .max(254, t("yupValidation.emailMax"))
            .required(t("yupValidation.emailRequired"))
        : Yup.string(),
    phone:
      channel === byPhone
        ? Yup.string()
            .matches(/^[0-9]+$/, t("yupValidation.phoneMatches"))
            .max(15, t("yupValidation.phoneMax"))
            .required(t("yupValidation.phoneRequired"))
        : Yup.string()
  });

  const Username = () => {
    const methodsMap = {
      [LoginMethods.EMAIL]: <ChannelEmail />,
      [LoginMethods.PHONE]: <ChannelPhone />,
      [LoginMethods.CHATBOT]: <ChannelChatbot chatbotNumber={chatbotNumber} />
    };

    const loginMethod = config.channeltoLoginMethod(channel);

    return <>{methodsMap[loginMethod]}</>;
  };

  if (config.isLoading) {
    return <NiloSpinner />;
  }

  return (
    <>
      <div className="w-full pb-8 pt-2 text-center">
        <h2 className="text-lg font-bold">{t("welcome")}</h2>
      </div>

      <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formik) => (
          <Form noValidate>
            {config.allowChangingLoginMethod() && (
              <div className="mb-8">
                <SelectChannel
                  defaultChannel={defaultChannel}
                  onChange={(value) => switchLoginMethod(value, formik)}
                />
              </div>
            )}

            <Username />
            <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center p-4 md:relative md:mt-2">
              {loginMethod !== LoginMethods.CHATBOT && (
                <NiloButton
                  type={typeButton.submit}
                  disabled={!formik.isValid || formik.isSubmitting}
                  className="w-full md:w-auto md:px-14 md:py-2"
                >
                  {t("nextButton")}
                </NiloButton>
              )}

              {config.get().requireRegister && (
                <div className="mt-4 flex items-center justify-center">
                  <span className="text-xs text-slate-900">
                    {t("signUpHeading")}
                  </span>
                  <NiloLink url="register">
                    <NiloButton
                      variant="link"
                      className="w-full md:w-auto md:py-2 md:ps-1"
                    >
                      {t("signUpButton")}
                    </NiloButton>
                  </NiloLink>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
