"use client";
import { NiloButton, NiloInput, typeButton, typeInput } from "@nilo/design";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoginContext } from "@nilo/auth/context/login";
import { useContext } from "react";
import { ChannelPhone } from "../username/channel-phone";
import { useTranslations } from "next-intl";
import { useAnalytics } from "@analytics";

interface Props {
  onSubmitCallback: (newPassword: string) => void;
  onForgotPassword: () => void;
  isLoading?: boolean;
}

export const SignInForm: React.FC<Props> = ({
  onSubmitCallback,
  onForgotPassword
}) => {
  const { username } = useContext(LoginContext);
  const t = useTranslations("auth.form.signIn");
  const { track } = useAnalytics("login");

  const initialValues = {
    password: "",
    email: username.email,
    phone: username.phoneNumber,
    countryCode: username.countryCode
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("yupValidation.passwordRequired"))
      .min(8, t("yupValidation.errorMessage"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/,
        t("yupValidation.errorMessage")
      )
  });

  const handlerSubmit = (values: { password: string }) => {
    onSubmitCallback(values.password);
    track.submitPassword();
  };

  const RenderUsername = () => {
    return username.email ? (
      <NiloInput name="email" label={t("usernameLabel")} disabled={true} />
    ) : (
      <ChannelPhone readonly={true} />
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handlerSubmit}
        validateOnMount
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <RenderUsername />
            <div className="my-4">
              <NiloInput
                type={typeInput.PASSWORD}
                id="password"
                name="password"
                label={t("passwordLabel")}
                maxLength={16}
              />
            </div>

            <div className="mt-6 flex justify-center md:mt-0 md:justify-end">
              <span
                className="text-bold cursor-pointer text-xs text-blue-500 hover:text-blue-700"
                onClick={onForgotPassword}
              >
                {t("forgotPasswordButton")}
              </span>
            </div>

            <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center p-4 md:relative md:mt-2">
              <NiloButton
                type={typeButton.submit}
                disabled={!isValid || isSubmitting}
                className="w-full md:w-auto md:px-14 md:py-2"
              >
                {t("loginButton")}
              </NiloButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
