"use client";
import { useContext, useEffect, useState } from "react";
import {
  SetPasswordRequired,
  UserAuthenticatedResponse,
  useInitAuthenticationMutation
} from "@nilo/codegen";
import { Credentials, ResponseHandler } from "@nilo/auth/interfaces";
import { saveSession } from "../utils/cookies";
import { getOrigin } from "../utils";
import { useTranslations } from "next-intl";
import { MessageType, UIContext } from "@nilo/design";

interface HookState {
  requireSetPassword: boolean;
  session?: string;
  isAuthenticated?: boolean;
  credentials?: Credentials;
  userTokens?: UserAuthenticatedResponse;
  loading?: boolean;
  isInvalidVerificationCode?: boolean;
}

enum authenticatedTypename {
  CHANGE_PASSWORD = "SetPasswordRequired",
  AUTHENTICATION = "UserAuthenticatedResponse"
}

enum SIGN_IN_EXCEPTIONS {
  INVALID_USERNAME = "InvalidUsernameException",
  INVALID_AUTHORIZATION_CODE = "InvalidAuthorizationCode"
}

const initState: HookState = {
  requireSetPassword: false,
  session: "",
  isAuthenticated: false,
  credentials: null,
  // error: null,
  userTokens: null,
  loading: false,
  isInvalidVerificationCode: false
};

const origin = getOrigin();

export const useSignin = () => {
  const [state, setState] = useState<HookState>({ ...initState });
  const t = useTranslations("auth.hooks.sign-in");
  const { showGlobalMessage } = useContext(UIContext);

  const [initAuthentication, { data, loading: isLoading }] =
    useInitAuthenticationMutation({
      onError: (error) => {
        const code =
          !!error[0] && !!error[0].code
            ? error[0].extensions.code
            : SIGN_IN_EXCEPTIONS.INVALID_USERNAME;

        showGlobalMessage({
          message: t("errorMessage"),
          type: MessageType.ERROR
        });

        setState({
          ...state,
          isInvalidVerificationCode:
            code === SIGN_IN_EXCEPTIONS.INVALID_AUTHORIZATION_CODE
        });
      }
    });

  useEffect(() => {
    !!data && authenticationHandler();
  }, [data]);

  const authenticationHandler = () => {
    const {
      initAuthentication: responseData,
      initAuthentication: { __typename }
    } = data;
    responseAuthHandlers[__typename](responseData);
  };

  //TODO mapear excepciones!

  const requireSetPasswordHandler: ResponseHandler<SetPasswordRequired> = ({
    session
  }) => {
    setState({
      ...state,
      session,
      requireSetPassword: true
    });
  };

  const userAuthenticatedHandler: ResponseHandler<
    UserAuthenticatedResponse
  > = async (user: UserAuthenticatedResponse) => {
    await saveSession(user);
    setState({ ...state, isAuthenticated: true, userTokens: user });
  };

  const responseAuthHandlers: Record<
    authenticatedTypename,
    ResponseHandler<any>
  > = {
    [authenticatedTypename.CHANGE_PASSWORD]: requireSetPasswordHandler,
    [authenticatedTypename.AUTHENTICATION]: userAuthenticatedHandler
  };

  const dispachtSingIn = async (credentials: Credentials) => {
    setState({ ...initState, credentials });
    await initAuthentication({
      variables: { input: { ...credentials, origin } }
    });
  };

  return {
    singIn: {
      dispachtSingIn,
      isLoading,
      requireSetPassword: state.requireSetPassword,
      session: state.session,
      isAuthenticated: state.isAuthenticated
    }
  };
};
