import { LOCAL_STORAGE } from "@nilo/constants";
import { useEffect, useState } from "react";

const useTermsAndConditions = () => {
  const [showCheckbox, setShowCheckbox] = useState<boolean>(true);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);

  useEffect(() => {
    const isCheckedStorage =
      localStorage.getItem(LOCAL_STORAGE.terms) === "true";

    if (isCheckedStorage) {
      setAgreeTerms(isCheckedStorage);
      setShowCheckbox(!isCheckedStorage);
    }
  }, []);

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setAgreeTerms(checked);

    localStorage.setItem(LOCAL_STORAGE.terms, JSON.stringify(checked));
  };

  return {
    showCheckbox,
    agreeTerms,
    handleTerms
  };
};

export default useTermsAndConditions;
