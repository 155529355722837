"use client";
import { LoginProvider } from "@nilo/auth/context/login";
import { Login, PropsLogin } from "./login";
import { usePlatformConfig } from "@nilo/auth/hooks/use-platform-config";
import { NiloSpinner } from "@nilo/design";
import { useTranslations } from "next-intl";
import { useEffect } from "react";

export const LoginWrapper = (props: PropsLogin) => {
  const { config } = usePlatformConfig(props.chatbotNumber);
  const t = useTranslations("auth");

  useEffect(() => {
    if (config) {
      config.set(props.platform);
    }
  }, [props.platform, config]);

  return (
    <LoginProvider>
      <div className="mx-auto max-w-lg">
        {config.isLoading ? (
          <NiloSpinner message={t("welcome")} />
        ) : (
          <Login {...props} />
        )}
      </div>
    </LoginProvider>
  );
};
