"use client";
import { useContext, useEffect, useState } from "react";
import { useSetPasswordMutation } from "@nilo/codegen";
import { saveSession } from "../utils/cookies";
import { getOrigin } from "../utils";
import { useTranslations } from "next-intl";
import { MessageType, UIContext } from "@nilo/design";

interface HookState {
  isAuthenticated?: boolean;
}

interface DistpatchSetPassword {
  session: string;
  username: string;
  newpassword: string;
}

const initState = {};

const origin = getOrigin();
export const useSetPassword = () => {
  const t = useTranslations("auth.hooks.set-password");
  const { showGlobalMessage } = useContext(UIContext);

  const [initChangePassword, { data, loading: isLoading }] =
    useSetPasswordMutation({
      onError: () =>
        showGlobalMessage({
          message: t("errorMessage"),
          type: MessageType.ERROR
        })
    });
  const [state, setState] = useState<HookState>(initState);

  useEffect(() => {
    !!data && userAuthenticatedHandler();
  }, [data]);

  const userAuthenticatedHandler = async () => {
    await saveSession(data.setPassword);
    setState({ isAuthenticated: true });
  };

  const clearState = () => {
    setState({ ...initState });
  };

  const dispachtSetPassword = async (credentials: DistpatchSetPassword) => {
    clearState();
    await initChangePassword({
      variables: { input: { ...credentials, origin } }
    });
  };

  return {
    setPassword: {
      dispachtSetPassword,
      isLoading,
      isAuthenticated: state.isAuthenticated
    }
  };
};
