import { createContext } from 'react';

export interface Username {
    email?: string;
    countryCode?: string;
    phoneNumber?: string;
}

interface ContextProps {
    username: Username;

    // Methods
    updateUsername: (newUsername: Username) => void;
}


export const LoginContext = createContext({} as ContextProps);

