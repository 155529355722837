"use client";
import { NiloInput, typeInput } from "@nilo/design";
import { useTranslations } from "next-intl";

export const ChannelEmail = () => {
  const t = useTranslations("auth.form.username.channel-email");
  return (
    <>
      <NiloInput
        name={"email"}
        label={t("label")}
        placeholder={t("placeholder")}
        type={typeInput.TEXT}
        onlyNumber={false}
      />
    </>
  );
};
