import { NextRequest } from "next/server";
import { jwtDecode } from "jwt-decode";
import { COOKIES } from "@nilo/constants";

const loginRegex = /^(\/(es|en|es-HN|es-AR|es-MX))?\/login(\/.*)?$/;

export const middlewareCheckToken = (
  request: NextRequest,
  publicPaths: Array<RegExp>
) => {
  const pathname = request.nextUrl.pathname;

  if (loginRegex.test(pathname) && isAuthenticated(request))
    return { status: 201 };

  if (
    !publicPaths.find((route) => route.test(pathname)) &&
    !isAuthenticated(request)
  )
    return { status: 401 };

  return { status: 200 };
};

const isIdTokenValid = (idToken: string) => !!idToken && !!jwtDecode(idToken);

export const isAuthenticated = (request: NextRequest) => {
  const cookie = request.cookies.get(COOKIES.session);
  try {
    const { idToken, refreshToken } = JSON.parse(cookie.value);
    return isIdTokenValid(idToken) && !!refreshToken;
  } catch (error) {
    return false;
  }
};
