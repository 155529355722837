import React, { useState, useRef, useEffect } from "react";
import { NiloSpinner } from "@nilo/design";
import { Channel } from "@nilo/codegen";
import { useTranslations } from "next-intl";

interface Props {
  deliveryMethod?: Channel;
  onSubmitCallback: (validationCode: string) => void;
  onResendCode: () => void;
  isLoading?: boolean;
  maxLengthCode?: number;
}

export const ValidationCodeForm: React.FC<Props> = ({
  deliveryMethod,
  onSubmitCallback,
  onResendCode,
  isLoading,
  maxLengthCode = 6
}) => {
  const [validationCode, setValidationCode] = useState("");
  const codeInputs = Array.from({ length: maxLengthCode }, () =>
    useRef<HTMLInputElement>(null)
  );
  const t = useTranslations("auth.form.validation-code");
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef.current && firstInputRef.current.focus();
  }, []);

  const handleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value.replace(/\D/g, "");
    const newCode = validationCode.split("");
    if (value.length === 1) newCode[index] = value;
    setValidationCode(newCode.join(""));

    if (newCode.length === maxLengthCode) onSubmitCallback(newCode.join(""));

    if (value.length === 1 && index < 5) codeInputs[index + 1].current?.focus();
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && index > 0)
      codeInputs[index - 1].current?.focus();
  };

  const RenderMessage = () => {
    let message = t("messages.deliveryMessage", {
      channel:
        deliveryMethod === Channel.Email
          ? t("messages.channelEmail")
          : t("messages.channelPhone")
    });

    return <div className="my-2">{message}</div>;
  };

  const resetInputs = () => {
    setValidationCode("");
    codeInputs.forEach((inputRef) => {
      if (inputRef.current) inputRef.current.value = "";
    });
  };

  const handlerOnResendCode = () => {
    resetInputs();
    onResendCode();
  };

  const RenderSpinner = () => {
    return (
      <div className="mt-8">
        <NiloSpinner message={t("spinnerMessage")} />
      </div>
    );
  };

  if (!deliveryMethod) return <RenderSpinner />;

  return (
    <div>
      <RenderMessage />
      <div className="relative flex">
        {Array.from({ length: maxLengthCode }, (_, index) => (
          <input
            key={index}
            className="mr-2 h-10 w-10 border-b-2 border-gray-400 text-center"
            type="text"
            maxLength={1}
            value={validationCode[index] || ""}
            onChange={(e) => handleCodeChange(e, index)}
            onKeyDown={(e) => handleKeyPress(e, index)}
            ref={index === 0 ? firstInputRef : codeInputs[index]}
            disabled={isLoading}
          />
        ))}
      </div>

      {isLoading && <RenderSpinner />}

      {!isLoading && (
        <div className="mt-8 flex justify-center">
          <span
            className="text-bold text-primary-500 hover:text-primary-700 underline"
            onClick={handlerOnResendCode}
          >
            {t("resendCode")}
          </span>
        </div>
      )}
    </div>
  );
};
