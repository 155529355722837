import { useTranslations } from "next-intl";

export const UserNotVerified = () => {
  const t = useTranslations("auth.login");
  return (
    <div className="mx-auto flex flex-col items-center justify-center">
      <img
        alt="Confirmed"
        className="mx-auto"
        height={100}
        src="/img/success.png"
        width={100}
      />

      <p className="mt-8 text-center text-sm">{t("salesRepNotVerified")}</p>
    </div>
  );
};
