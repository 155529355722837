import { useEffect } from "react";
import { useField } from "formik";
import { Channel } from "@nilo/codegen";

interface RadioButtonProps {
  name: string;
  options: Array<{ label: string; value: string }>;
  className?: string;
  onChange: (value: string) => void;
  defaultChannel: Channel;
}

export const Selector: React.FC<RadioButtonProps> = ({
  name,
  options,
  onChange,
  defaultChannel
}) => {
  const [field, , helpers] = useField(name);

  useEffect(() => {
    helpers.setValue(defaultChannel);
  }, []);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    helpers.setValue(value);
    onChange(value);
  };

  return (
    <div className="flex justify-around rounded-3xl bg-white text-slate-400">
      {options.map((option) => (
        <div
          key={option.value}
          className={`w-full p-3 ${field.value === option.value && "rounded-3xl bg-slate-900 text-white"}`}
          data-test-id={`userType${option.value}`}
        >
          <input
            type="radio"
            className="peer hidden"
            id={option.value}
            name={name}
            value={option.value}
            checked={field.value === option.value}
            onChange={handleRadioChange}
          />
          <label
            htmlFor={option.value}
            className="flex cursor-pointer items-center justify-center text-xs"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
