import { Channel } from "@nilo/codegen";
import { Selector } from "./Selector";
import { useTranslations } from "next-intl";
import { LoginMethods } from "@nilo/auth/utils/platform-config";

interface Props {
  onChange: (channel: LoginMethods) => void;
  defaultChannel: Channel;
}

export const SelectChannel = ({ onChange, defaultChannel }: Props) => {
  const t = useTranslations("auth.form.username.select-channel");
  const options = [
    { label: t("labelPhone"), value: Channel.Phone },
    { label: t("labelEmail"), value: Channel.Email }
  ];

  return (
    <Selector
      defaultChannel={defaultChannel}
      name="userNameType"
      options={options}
      onChange={(value) => onChange(value as LoginMethods)}
    />
  );
};
