import { NiloButton, NiloInput, typeButton, typeInput } from "@nilo/design";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordRestrictions } from "./password-restrictions";
import { useState } from "react";
import { useTranslations } from "next-intl";
import useTermsAndConditions from "@nilo/auth/hooks/use-terms-and-conditions";
import TermsAndConditions from "../terms-and-conditions";
import { useAnalytics } from "@analytics";

interface Props {
  onSubmitCallback: (newPassword: string) => void;
}

export const SetPasswordForm: React.FC<Props> = ({ onSubmitCallback }) => {
  const [state, setState] = useState({ newPasswordTouched: false });
  const t = useTranslations("auth.form.set-password");
  const { agreeTerms, handleTerms, showCheckbox } = useTermsAndConditions();
  const { track } = useAnalytics("login");

  const initialValues = {
    newPassword: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required()
      .min(8)
      .max(16)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("yupValidation.confirmPassword"))
      .required(t("yupValidation.passwordRequired"))
      .oneOf([Yup.ref("newPassword"), null], t("yupValidation.confirmPassword"))
      .required(t("yupValidation.passwordRequired"))
  });

  const handleSubmit = (values: { newPassword: string }) => {
    onSubmitCallback(values.newPassword);
    track.confirmResetPassword();
  };

  const newPasswordChange = () => {
    if (state.newPasswordTouched) return;
    setState({ ...state, newPasswordTouched: true });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ isValid, isSubmitting, values }) => (
        <Form>
          <div className="mb-4 text-xs text-slate-900">
            {t("newPasswordHeading")}
          </div>

          <div className="mb-6 mt-4">
            <NiloInput
              type={typeInput.PASSWORD}
              id="newPassword"
              name="newPassword"
              label={t("newPasswordLabel")}
              hideError={true}
              placeholder={t("newPasswordPlaceholder")}
              onChange={newPasswordChange}
            />

            <div className="mt-4">
              <PasswordRestrictions
                newPassword={values.newPassword}
                touched={state.newPasswordTouched}
              />
            </div>
          </div>

          <div className="mb-4">
            <NiloInput
              type={typeInput.PASSWORD}
              id="confirmPassword"
              name="confirmPassword"
              label={t("confirmPasswordLabel")}
              placeholder={t("newPasswordPlaceholder")}
            />
          </div>

          <div className="fixed bottom-0 left-0 right-0 flex flex-col justify-center p-4 md:relative md:mt-2">
            <div className="flex justify-center p-3">
              {showCheckbox && (
                <TermsAndConditions
                  agreeTerms={agreeTerms}
                  handleTerms={handleTerms}
                />
              )}
            </div>
            <div className="flex justify-center">
              <NiloButton
                type={typeButton.submit}
                disabled={!isValid || !agreeTerms || isSubmitting}
                className="w-full md:w-auto md:px-14 md:py-2"
              >
                {t("updatePasswordButton")}
              </NiloButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
