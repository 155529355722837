import { useAuthorizedDeviceAuthMutation } from "@nilo/codegen";
import { saveSession, saveUuidSession } from "../utils/cookies";
import { getOrigin } from "../utils";
import { useTranslations } from "next-intl";
import { getToken } from "../session/get-token";
import { useState } from "react";
import { COOKIES, InitAuthenticationErrorCode } from "@nilo/constants";
import { parseCookies } from "nookies";

interface ChatbotAuthPayload {
  uuid: string;
}

const origin = getOrigin();

export const useChatbotSignin = () => {
  const t = useTranslations("auth.hooks.chatbot-sign-in");
  const [isUserValidated, setIsUserValidated] = useState<boolean>(false);

  const chatbotAuthErrorMapper = (graphQLError) => {
    switch (graphQLError.code) {
      case InitAuthenticationErrorCode.UUID_NOT_FOUND:
        return t("errorMessage");

      default:
        return t("errorMessage");
    }
  };

  const [authorizedDeviceAuth, { loading: isLoading, error }] =
    useAuthorizedDeviceAuthMutation();

  const getUuidSession = () => {
    const cookies = parseCookies();
    if (!cookies[COOKIES.uuid]) return null;
    return cookies[COOKIES.uuid];
  };

  const dispatchChatbotSignIn = async ({ uuid }: ChatbotAuthPayload) => {
    const token = await getToken();
    const uuidSession = getUuidSession();
    if (token && uuidSession === uuid) {
      setIsUserValidated(true);
      return;
    }

    await authorizedDeviceAuth({
      variables: { input: { uuid, origin } },
      onError: (err) =>
        chatbotAuthErrorMapper[`${err.graphQLErrors[0].extensions}`],
      onCompleted: async ({ authorizedDeviceAuth }) => {
        await saveSession(authorizedDeviceAuth);
        await saveUuidSession({ uuid });
        setIsUserValidated(true);
      }
    });
  };

  return {
    dispatchChatbotSignIn,
    isLoading,
    error,
    isUserValidated
  };
};
