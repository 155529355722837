import { Platform } from "@nilo/codegen";

export enum LoginMethods {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  CHATBOT = "CHATBOT"
}
export interface PlatformConfig {
  platform: Platform;
  loginMethods: Array<LoginMethods>;
  chatbotNumber?: string;
  requireRegister?: boolean;
  requireVerify?: boolean;
  requireValidationCode?: boolean;
}

export const getPlatformConfig = (
  platform: Platform,
  chatbotNumber: string
) => {
  const config = {
    [Platform.Admin]: {
      platform: Platform.Admin,
      loginMethods: [LoginMethods.EMAIL],
      requireRegister: true,
      requireValidationCode: true
    },
    [Platform.Pwa]: {
      platform: Platform.Pwa,
      loginMethods: [LoginMethods.EMAIL, LoginMethods.CHATBOT],
      chatbotNumber: chatbotNumber,
      requireRegister: true,
      requireValidationCode: true,
      requireVerify: true
    },
    [Platform.Sfa]: {
      platform: Platform.Sfa,
      loginMethods: [LoginMethods.PHONE],
      requireVerify: true
    }
  };
  return config[platform];
};
