import { Checkbox, Typography } from "@material-tailwind/react";
import Link from "next/link";
import { useTranslations } from "next-intl";

const TermsAndConditions = ({
  agreeTerms,
  handleTerms
}: {
  agreeTerms: boolean;
  handleTerms: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const t = useTranslations("auth.form.terms");
  return (
    <Checkbox
      checked={agreeTerms}
      label={
        <Typography className="text-xs">
          {t("agreed")}
          <Link href={"#"} className={"font-bold text-blue-600"}>
            {t("terms")}
          </Link>
        </Typography>
      }
      onChange={handleTerms}
      color="light-blue"
    />
  );
};

export default TermsAndConditions;
