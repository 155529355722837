/* eslint-disable prettier/prettier */
const Routes = {
    shopping: "shopping",
    cart: "shopping/cart",
    checkout: "shopping/checkout",
    promotions: "shopping/promotions",
    promotionDetails: (id: string) => `shopping/promotions/${id}`,
    orders: "shopping/orders",
    orderDetails: (id: string) => `shopping/orders/${id}`,
    profile: "shopping/profile",
    login: "/login",
    product: (id: string) => `shopping/product/${id}`,
    suggestions: "shopping/suggestions",
    home: "shopping"
};

export default Routes;