import { AuthNextStep, Channel } from "@nilo/codegen";
import { PlatformConfig } from "./platform-config";

export const getValidations = ({
  config,
  channel,
  step
}: {
  config: PlatformConfig;
  channel: Channel;
  step: AuthNextStep;
}) => {
  const isChannelPhone = channel === Channel.Phone;
  const isVerify = step === AuthNextStep.Verify;
  const isSignUp = step === AuthNextStep.SignUp;
  const isSignIn = step === AuthNextStep.SignIn;

  return {
    showUsername: !step || isSignUp,
    showCheckCredentials: isSignIn,
    showValidationCode:
      config.requireValidationCode && !isChannelPhone && isVerify,
    showUserNotVerified: config.requireVerify && isChannelPhone && isVerify
  };
};
