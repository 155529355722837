import { NiloAlert } from "@nilo/design";
import { LoginWrapper } from "../login";
import { useTranslations } from "next-intl";
import { Platform } from "@nilo/codegen";

interface Credentials {
  username: string;
  code: string;
}

const getUsernameByParam = (hash: string): Credentials => {
  try {
    return JSON.parse(atob(hash));
  } catch (_) {
    return { username: null, code: null };
  }
};

interface Props {
  platform: Platform;
  hash: string;
  loginSuccessCallback: () => void;
}

export const AuthConfirmUser = ({
  hash,
  loginSuccessCallback,
  platform
}: Props) => {
  const { username, code } = getUsernameByParam(hash);
  const t = useTranslations("auth");

  return (
    <>
      <div className="w-full pb-8 pt-2 text-center">
        <h2 className="text-lg font-bold">{t("welcome")}</h2>
      </div>
      {username ? (
        <LoginWrapper
          platform={platform}
          loginSuccessCallback={loginSuccessCallback}
          isConfirmationLink={true}
          usernameByHash={username}
          codeByHash={code}
        />
      ) : (
        <div className="mt-4">
          <NiloAlert message={t("confirmUserExpired")} />
        </div>
      )}
    </>
  );
};
