"use client";
import { Channel, Platform } from "@nilo/codegen";
import {
  getPlatformConfig,
  LoginMethods,
  PlatformConfig
} from "../utils/platform-config";
import { LOCAL_STORAGE } from "@nilo/constants";
import { useEffect, useState } from "react";

interface PlatformConfigResponse {
  config: {
    get: () => PlatformConfig;
    set: (platform: Platform) => void;
    allowChangingLoginMethod: () => boolean;
    defaultLoginMethod: () => LoginMethods;
    isLoading: boolean;
    channel: () => Channel;
    channeltoLoginMethod: (channel: Channel) => LoginMethods;
  };
}

export const usePlatformConfig = (
  chatbotNumber?: string
): PlatformConfigResponse => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConfig();
    setIsLoading(false);
  }, []);

  const setPlatform = (platform: Platform) => {
    localStorage.setItem(LOCAL_STORAGE.platform, platform);
  };

  const getConfig = (): PlatformConfig => {
    const savedPlatform = localStorage.getItem(
      LOCAL_STORAGE.platform
    ) as Platform;

    return getPlatformConfig(savedPlatform, chatbotNumber);
  };

  const allowChangingLoginMethod = () => getConfig().loginMethods.length > 1;

  const defaultLoginMethod = () => getConfig().loginMethods[0];

  const channel = () =>
    defaultLoginMethod() === LoginMethods.CHATBOT
      ? Channel.Phone
      : (defaultLoginMethod() as unknown as Channel);

  const channeltoLoginMethod = (channel: Channel): LoginMethods => {
    const hasChatbot = getConfig().loginMethods.includes(LoginMethods.CHATBOT);

    return channel === Channel.Phone && hasChatbot
      ? LoginMethods.CHATBOT
      : (channel as unknown as LoginMethods);
  };

  return {
    config: {
      get: getConfig,
      set: setPlatform,
      allowChangingLoginMethod,
      defaultLoginMethod,
      isLoading,
      channel,
      channeltoLoginMethod
    }
  };
};
