import { useChatbotSignin } from "@nilo/auth/hooks/use-chatbot-sign-in";
import { NiloSpinner } from "@nilo/design";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { SupportNumber } from "./support-number";

interface Props {
  uuid: string;
  loginSuccessCallback: () => void;
  phoneNumber?: string | number;
}

export const AuthChatbot = ({
  uuid,
  loginSuccessCallback,
  phoneNumber
}: Props) => {
  const t = useTranslations("auth.form.chatbot");

  const { dispatchChatbotSignIn, isLoading, error, isUserValidated } =
    useChatbotSignin();

  useEffect(() => {
    if (isUserValidated) return loginSuccessCallback();

    dispatchChatbotSignIn({ uuid });
  }, [uuid, isUserValidated]);

  return (
    <>
      {isLoading && <NiloSpinner message={t("spinnerMessage")} />}
      {error && <SupportNumber phoneNumber={phoneNumber} />}
    </>
  );
};
