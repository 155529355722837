"use client";
import { useState, useEffect, useContext } from "react";
import {
  AuthNextStep,
  UserCheckedResponse,
  useCheckQuery
} from "@nilo/codegen";
import { ErrorMessage, ResponseHandler } from "@nilo/auth/interfaces";
import { getOrigin } from "../utils";
import { InitAuthenticationErrorCode } from "@nilo/constants";
import { isErrorKey } from "@nilo/design/src/utils";
import { useTranslations } from "next-intl";
import { MessageType, UIContext } from "@nilo/design";

export interface resultUsername {
  nextStep?: AuthNextStep;
  error?: ErrorMessage;
  username: string;
}

interface HookProps {
  userCheckedCallback: (param: resultUsername) => void;
}

const origin = getOrigin();

export const useCheckUsername = ({ userCheckedCallback }: HookProps) => {
  const { showGlobalMessage } = useContext(UIContext);
  const [username, setUsername] = useState<string>("");
  const t = useTranslations("auth.hooks.username");

  const { data, loading } = useCheckQuery({
    variables: {
      input: {
        username,
        origin
      }
    },
    skip: !username,
    onError: (error) => {
      console.log(error);

      const message = isErrorKey(
        error,
        InitAuthenticationErrorCode.USER_NOT_FOUND
      )
        ? t("userNotFoundMessage")
        : t("errorMessage");

      showGlobalMessage({
        message,
        type: MessageType.ERROR
      });
    }
  });

  useEffect(() => {
    !!data && userCheckedHandler(data.check);
  }, [data]);

  //TODO: mapeo de mensajes de error

  const userCheckedHandler: ResponseHandler<UserCheckedResponse> = (
    checkedResponse: UserCheckedResponse
  ) => {
    userCheckedCallback({ username, nextStep: checkedResponse.nextStep });
  };

  return {
    setUsername,
    isLoading: loading
  };
};
