import { UsernameForm } from "@nilo/auth/components/forms";
import {
  resultUsername,
  useCheckUsername
} from "@nilo/auth/hooks/use-username";
import { Channel } from "@nilo/codegen";

interface Props {
  onChannelSelected?: (channel: Channel) => void;
  userCheckedCallback: (param: resultUsername) => void;
  defaultChannel: Channel;
  chatbotNumber?: string;
}

const UsernameContainer: React.FC<Props> = ({
  userCheckedCallback,
  onChannelSelected,
  defaultChannel,
  chatbotNumber
}: Props) => {
  const { setUsername, isLoading } = useCheckUsername({
    userCheckedCallback
  });

  return (
    <UsernameForm
      onSubmitCallback={setUsername}
      onChannelSelected={onChannelSelected}
      isLoading={isLoading}
      defaultChannel={defaultChannel}
      chatbotNumber={chatbotNumber}
    />
  );
};

export default UsernameContainer;
