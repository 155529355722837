import { NiloInput } from "@nilo/design";
import { FC } from "react";
import { CountryPhoneCode } from "./country-phone-code";
import { useTranslations } from "next-intl";

interface Props {
  readonly?: boolean;
}

export const ChannelPhone: FC<Props> = ({ readonly = false }) => {
  const t = useTranslations("auth.form.username.channel-phone");
  return (
    <>
      <label htmlFor="phone" className="block text-xs text-slate-900">
        {readonly ? t("label.true") : t("label.false")}
      </label>
      <div className="relative mt-2 focus:outline-none">
        <CountryPhoneCode readonly={readonly} />

        <NiloInput
          name="phone"
          label=""
          onlyNumber={true}
          classInput={"block pr-3 pl-20"}
          hideError={false}
          disabled={readonly}
          placeholder={t("placeholder")}
        />
      </div>
    </>
  );
};
