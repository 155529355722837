import { FC, useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button
} from "@material-tailwind/react";
import { useField } from "formik";
import countries from "./country-codes.json";

interface countries {
  name: string;
  countryCallingCode: string;
  flag: any;
}

export const countryCodeDefault = countries[0].countryCallingCode;

interface Props {
  readonly?: boolean;
}

export const CountryPhoneCode: FC<Props> = ({ readonly = false }) => {
  const [field, meta, helpers] = useField("countryCode");
  const initValue = countries.find(
    (country) =>
      country.countryCallingCode === (field.value || countryCodeDefault)
  );
  const [country, setCountry] = useState<countries>(initValue);

  const onChangeCountry = (countrySelected: countries) => {
    setCountry(countrySelected);
    const code = countrySelected.countryCallingCode;
    helpers.setValue(code);
  };

  const RenderSelected = () => {
    return (
      <div className="flex h-full items-center bg-transparent px-2 text-xs font-semibold">
        <span className="h-4 w-4 rounded-full object-cover">
          {country.flag}
        </span>
        <span
          className={`ml-2 ${readonly ? "text-slate-400" : "text-slate-900"}`}
        >
          {country.countryCallingCode}
        </span>
      </div>
    );
  };

  if (readonly)
    return (
      <div className="absolute left-1 top-[13px]">
        <RenderSelected />
      </div>
    );

  return (
    <div className="absolute left-[2px] top-[2px]">
      <Menu placement="bottom-start">
        <MenuHandler>
          <Button
            ripple={false}
            variant="text"
            color="blue-gray"
            className="min-w-16 px-0 font-normal focus:outline-none"
            data-testid="country-selector-button"
          >
            <RenderSelected />
          </Button>
        </MenuHandler>

        <MenuList className="min-w-[190px] overflow-y-auto">
          {countries.map((item, index) => {
            return (
              <MenuItem
                key={item.name}
                value={item.name}
                className="mb-4 flex items-center justify-between focus:outline-none focus-visible:outline-none"
                onClick={() => onChangeCountry(item)}
                data-testid={`country-option-${item.name}`}
              >
                <div className="text-slate-900 ">
                  <span className="mr-3 h-4 w-4 rounded-full object-cover">
                    {item.flag}
                  </span>
                  {item.name}
                </div>

                <div className="text-slate-900 ">{item.countryCallingCode}</div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};
