import { FC, useReducer } from 'react';
import { LoginContext, Username, loginReducer } from '.';

export interface UIState {
    username: Username;
}

const UI_INITIAL_STATE: UIState = {
    username: null,
}

export const LoginProvider: FC<any> = ({ children }) => {
    const [state, dispatch] = useReducer(loginReducer, UI_INITIAL_STATE);

    const updateUsername = (newUsername: Username) => {
        dispatch({ type: '[LOGIN] - Update Username', payload: newUsername });
    }

    return (
        <LoginContext.Provider value={{
            ...state,

            // Methods
            updateUsername,
        }}>
            {children}
        </LoginContext.Provider>
    )
};