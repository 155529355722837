import { XMarkIcon } from "@heroicons/react/24/outline";
import { NiloButton } from "@nilo/design";
import { createWhatsappPath } from "@nilo/design/src/utils";
import { useTranslations } from "next-intl";
import Link from "next/link";

export const SupportNumber = ({ phoneNumber }) => {
  const t = useTranslations("auth.form.chatbot.support-number");
  return (
    <div className="flex min-h-96 flex-col items-center justify-center gap-6 ">
      <XMarkIcon className="h-20 cursor-pointer rounded-full border-4 border-red-500 p-2 text-red-500 transition-all hover:opacity-50" />
      <div className="p-2 text-center text-sm">
        <p>{t("message")}</p>
      </div>
      <Link href={createWhatsappPath(phoneNumber)}>
        <NiloButton className="flex items-center justify-between bg-blue-600 px-3 py-3">
          {t("supportButton")}
        </NiloButton>
      </Link>
    </div>
  );
};
