"use client";

import { NiloButton } from "@nilo/design";
import { createWhatsappPath } from "@nilo/design/src/utils";
import { useTranslations } from "next-intl";
import Link from "next/link";

interface Props {
  chatbotNumber?: string;
}

export const ChannelChatbot = ({ chatbotNumber }: Props) => {
  const t = useTranslations("auth.form.username.channel-chatbot");

  return (
    <div className="m-auto flex max-w-80 flex-col items-center justify-center">
      <p className="text-center text-xs text-slate-900">{t("label")}</p>

      <Link href={createWhatsappPath(chatbotNumber)}>
        <NiloButton className="bg-principal-500 mt-4 w-[160px] px-3 py-3">
          {t("redirect")}
        </NiloButton>
      </Link>
    </div>
  );
};
