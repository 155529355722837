import { NextRequest, NextResponse } from "next/server";
import { jwtDecode } from "jwt-decode";
import { COOKIES } from "@nilo/constants";

export const middlewareBase = (
  request: NextRequest,
  routeRedirect: string = "/login"
): NextResponse => {
  if (!isAuthenticated(request))
    return NextResponse.redirect(new URL(routeRedirect, request.url));
};

export const isAuthenticated = (request: NextRequest) => {
  const cookie = request.cookies.get(COOKIES.session);
  try {
    const { idToken } = JSON.parse(cookie.value);

    return !!idToken && !!jwtDecode(idToken);
  } catch (error) {
    return false;
  }
};
