import { useEffect, useState } from "react";
import {
  useConfirmForgotPasswordMutation,
  useForgotPasswordMutation
} from "@nilo/codegen";
import { ErrorMessage } from "@nilo/auth/interfaces";
import { getOrigin } from "../utils";
import { useTranslations } from "next-intl";

interface HookState {
  error?: ErrorMessage;
  isForgot?: boolean;
  needAuthenticattion?: boolean;
  newPassword?: string;
}

interface DispatchSetPassword {
  session: string;
  username: string;
  newpassword: string;
}

interface dispatchConfirmPassword {
  username: string;
  confirmationCode: string;
  newPassword: string;
}

const initState = {};

const origin = getOrigin();

export const useForgotPassword = () => {
  const [state, setState] = useState<HookState>(initState);
  const t = useTranslations("auth.hooks.forgotPassword");
  const errorMessage = t("errorMessage");

  const [forgotPasswordMutation, { data, loading: isLoading, error }] =
    useForgotPasswordMutation();

  const [
    confirmForgotPasswordMutation,
    {
      loading: isConfirmingForgotPassword,
      error: confirmForgotError,
      data: confirmForgotData
    }
  ] = useConfirmForgotPasswordMutation();

  useEffect(() => {
    !!error && apiErrorhandler();
    !!confirmForgotError && confirmForgotErrorHandler();
    !!data && emitIsForgot();
    !!confirmForgotData && emitNeedAuthentication();
  }, [data, error, confirmForgotError, confirmForgotData]);

  const emitIsForgot = () => {
    setState({ ...state, isForgot: true });
  };

  const emitNeedAuthentication = () => {
    const isSuccess =
      confirmForgotData.confirmForgotPassword.status === "SUCCESS";
    const error = !isSuccess
      ? { message: t("succesMessage.errorMessage") }
      : null;
    setState({ ...state, needAuthenticattion: isSuccess, error });
  };

  const apiErrorhandler = () => {
    const code =
      !!error[0] && !!error[0].code
        ? error[0].extensions.code
        : "InvalidUsernameException";
    setState({ ...state, error: { code, message: errorMessage } });
  };

  const confirmForgotErrorHandler = () => {
    setState({
      ...state,
      error: { code: confirmForgotError[0]?.code, message: errorMessage }
    });
  };

  const clearState = () => {
    setState({ ...initState });
  };

  const dispatchForgotPassword = async (username: string) => {
    clearState();
    await forgotPasswordMutation({
      variables: { input: { username, origin } }
    });
  };

  const dispatchConfirmPassword = async ({
    username,
    confirmationCode,
    newPassword
  }: dispatchConfirmPassword) => {
    clearState();
    setState({ ...state, newPassword });
    await confirmForgotPasswordMutation({
      variables: {
        input: {
          username,
          confirmationCode,
          newPassword,
          origin
        }
      }
    });
  };

  return {
    forgotPassword: {
      dispatch: dispatchForgotPassword,
      isLoading,
      error: state.error,
      isForgotMessage: state.isForgot
    },
    confirmForgotPassword: {
      mutate: confirmForgotPasswordMutation,
      isLoading: isConfirmingForgotPassword,
      dispatch: dispatchConfirmPassword,
      automaticSignin: state.needAuthenticattion,
      newPassword: state.newPassword
    }
  };
};
