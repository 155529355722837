"use client";
import { Channel, useResendVerificationCodeMutation } from "@nilo/codegen";
import { useContext, useState } from "react";
import { useTranslations } from "next-intl";
import { MessageType, UIContext } from "@nilo/design";
import { getOrigin } from "../utils";

interface HookState {
  deliveryMethod?: Channel;
}

export const useValidationCode = () => {
  const t = useTranslations("auth.hooks.validation-code");
  const { showGlobalMessage } = useContext(UIContext);
  const [state, setState] = useState<HookState>({});
  const origin = getOrigin();

  const [sendCodeMutation, { loading: isLoading }] =
    useResendVerificationCodeMutation({
      onCompleted: (data) => {
        const {
          resendVerificationCode: { deliveryDetails }
        } = data;
        codeSent(deliveryDetails.channel);
      },
      onError: () =>
        showGlobalMessage({
          message: t("errorMessage"),
          type: MessageType.ERROR
        })
    });

  const distpatchSendCode = async (username: string) => {
    try {
      setState({});
      await sendCodeMutation({ variables: { input: { username, origin } } });
    } catch (err) {}
  };

  const codeSent = (channel: Channel) => {
    setState({ deliveryMethod: channel });
  };

  return {
    validationCode: {
      isLoading,
      distpatchSendCode,
      deliveryMethod: state.deliveryMethod
    }
  };
};
